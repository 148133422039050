import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            component: (resolve) => require(['@/views/login'], resolve),
            hidden: true
        },
        {
            path: '/',
            component: (resolve) => require(['@/views/Index'], resolve),
            hidden: true,
            children: [
                {
                    path: '/home',
                    component: (resolve) => require(['@/views/home'], resolve),
                    name: 'home',
                },
                {
                    path: '/detail_customer',
                    component: (resolve) => require(['@/views/detail_customer'], resolve),
                    name: 'detail_customer',
                },
                {
                    path: '/view_course_list',
                    component: (resolve) => require(['@/views/view_course_list'], resolve),
                    name: 'view_course_list',
                },
				{
                    path: '/calendar',
                    component: (resolve) => require(['@/views/calendar'], resolve),
                    name: 'calendar',
                },
                {
                    path: '/refund_list',
                    component: (resolve) => require(['@/views/refund_list'], resolve),
                    name: 'refund_list',
                }
            ]
        }
    ]
})
